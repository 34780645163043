import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types";

const SEO = ({ title }) => {
  const { site } = useStaticQuery(query)
  const {
    defaultSiteUrl,
    defaultTitle,
  } = site.siteMetadata

  const seo = {
    title: title ? `${title} | ${defaultTitle}` : defaultTitle,
    siteUrl: defaultSiteUrl,
  }

  return (
    <Helmet>
      <html lang="ja" />
      <title>{seo.title}</title>
      <meta property="og:locale" content="ja_JP" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={seo.title} />
      <meta property="og:url" content={seo.siteUrl} />
      <meta property="og:site_name" content={seo.title} />
    </Helmet>
  )
}

export default SEO;

const query = graphql`
#  query($slug: String!) {
      query {
#    markdownRemark(fields: { slug: { eq: $slug } }) {
#        html
#        frontmatter {
#            date(formatString: "MMMM DD, YYYY")
#            slug
#            title
#        }
#        fields {
#            authorTime(formatString: "YYYY-MM-DD")
#        }
#    }
    site {
      siteMetadata {
        defaultSiteUrl: siteUrl,
        defaultTitle: title,
      }
    }
  }
`

SEO.propTypes = {
  title: PropTypes.string
}

