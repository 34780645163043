import React from 'react';
import Card from '../components/Card';
import Layout from '../components/layout/Layout';
import SplitSection from '../components/SplitSection';
import HeroImage from '../svg/HeroImage';
import MovieEdit from "../../assets/movieEdit.svg";
import CheckList from "../../assets/checklist.svg";
import Upload from "../../assets/upload.svg";
import SEO from '../components/seo';
import ButtonLink from '../components/ButtonLink';

const Index = () => (
  <Layout>
    <SEO />
    <section className="py-16 lg:pt-48">
      <div className="container mx-auto px-8 lg:flex">
        <div className="text-center lg:text-left lg:w-1/2">
          <h1 className="text-2xl xs:text-3xl sm:text-3xl lg:text-5xl xl:text-6xl font-bold leading-none">
            クラウドマニュアルで現場を仕組み化
          </h1>
          <div>
            <p className="xs:text-lg sm:text-2xl lg:text-2xl mt-6 md:mt-12 font-light">
              新人教育の時間が増え続けている。人手不足で教育に手が回らない。
              上司の指示が現場で徹底されない。配属されたが誰に聞いたらいいのかわからない。
            </p>
            <p className="text-2xl lg:text-3xl mt-8 font-light">
              クラウドマニュアル osheeru（オシエル）が解決します。
            </p>
          </div>
          <div className="mt-8 md:mt-12">
            <ButtonLink size="lg" className='rounded-3xl' path={'/contact'}>お問い合わせ</ButtonLink>
          </div>
        </div>
        <div className="lg:w-1/2">
          <HeroImage />
        </div>
      </div>
    </section>
    <section className="mt-16 py-16 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">現場の悩み</h2>
        <div className="grid grid-cols-1 pt-16 md:pt-16 md:grid-cols-3  sm:-mx-3">
          <div className="mx-3">
            <Card className="mb-8 min-h-256px bg-white">
              <p className="font-semibold text-xl">教育できるスタッフが足りない。</p>
              <p className="mt-4">
                慢性的な人手不足で社員教育ができるスタッフが足りない。
              </p>
            </Card>
          </div>
          <div className="mx-3">
            <Card className="mb-8 min-h-256px bg-white">
              <p className="font-semibold text-xl">業務に追われて社員教育がおろそかに</p>
              <p className="mt-4">
                自分の業務に追われて、教育まで手が回らない。新人が育たずに業務の負荷だけが上がる悪循環。
              </p>
            </Card>
          </div>
          <div className="mx-3">
            <Card className="mb-8 min-h-256px bg-white">
              <p className="font-semibold text-xl">上司・管理職の指示・手順が徹底されない</p>
              <p className="mt-4">
                口頭での指示が伝わらず、作業に抜け漏れが発生する。
              </p>
            </Card>
          </div>
          <div className="mx-3">
            <Card className="mb-8 min-h-256px bg-white">
              <p className="font-semibold text-xl">誰に聞いたらいいかわからない</p>
              <p className="mt-4">
                新人として配属されたけど、わからないことを誰に聞いたらいいのかわからない。みんな忙しそうで聞きにくい。
              </p>
            </Card>
          </div>
          <div className="mx-3">
            <Card className="mb-8 min-h-256px bg-white">
              <p className="font-semibold text-xl">言った言わない、やったやらない問題</p>
              <p className="mt-4">
                仕事の指示が口頭なため、抜け、漏れが発生してしまう。
              </p>
            </Card>
          </div>
          <div className="mx-3">
            <Card className="mb-8 min-h-256px bg-white">
              <p className="font-semibold text-xl">社員がやめてしまう</p>
              <p className="mt-4">
                スキルのある社員が突然やめてしまい、業務が回らなくなった。残った社員に十分な引き継ぎがされない。
              </p>
            </Card>
          </div>
        </div>
      </div>
    </section>
    <section className="mt-16 pt-16">
      <div className="container px-16 mx-auto text-center">
        <h2 className="text-3xl lg:text-5xl font-semibold">osheeruが解決できること</h2>
        <p className="p-8 text-xl lg:text-2xl mt-6 font-light">
          DXが進む中、マニュアルを電子化するのは普通になりました。<br/>一般的なクラウドマニュアルの機能に加えて、osheeruが解決できることがあります。
        </p>
      </div>
    </section>
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-2xl font-semibold leading-tight">
            チェックリスト付きマニュアルで実施チェックができる
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            実施結果を確認したい場合は、チェックリスト付きマニュアルを担当者に送信しましょう。担当者が実施したかチェックできます。
          </p>
        </div>
      }
      secondarySlot={<CheckList />}
    />
    <SplitSection
      primarySlot={
        <div className="lg:pr-32 xl:pr-48">
          <h3 className="text-2xl font-semibold leading-tight">
            作業実施状況をアップロードできる
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            チェックリストの確認だけで不安な場合は、実施結果として画像や動画をアップロードでしましょう。担当者の実施内容を確実にチェックできます。
          </p>
        </div>
      }
      secondarySlot={<Upload />}
    />
    <SplitSection
      reverseOrder
      primarySlot={
        <div className="lg:pl-32 xl:pl-48">
          <h3 className="text-2xl font-semibold leading-tight">
            Osheeru上でカンタンに画像や動画を編集できる。
          </h3>
          <p className="mt-8 text-xl font-light leading-relaxed">
            文章で伝割りにくい場合は、マニュアルに画像や動画を付け加えましょう。高価な画像・動画編集ソフトは不要です。
          </p>
        </div>
      }
      secondarySlot={<MovieEdit/>}
    />

    <section className="mt-16 py-16 bg-gray-100">
      <div className="container mx-auto text-center">
        <h3 className="text-3xl lg:text-5xl font-semibold">機能</h3>
        <p className="text-xl lg:text-2xl mt-6 font-light">
          業界標準の機能を揃えております。
        </p>
        <div className="mt-8 xs:w-11/12 sm:w-full md:w-8/12 mx-auto ">
          <div className={"p-8 sm:p-16 rounded-lg border border-solid border-gray-200 divide-y bg-white"}>
            <div className={'sm:grid sm:grid-cols-2'}>
              <div className={'text-left text-lg sm:text-xl font-semibold'}>画像の編集</div>
              <div className={'text-left text-sm sm:text-base mt-2 sm:mt-0'}>文字で伝わりにくい場合は画像で説明できます。画像に文字や番号を付与できます。</div>
            </div>
            <div className={'sm:grid sm:grid-cols-2 mt-4 pt-4'}>
              <div className={'text-left text-lg sm:text-xl font-semibold'}>動画の編集</div>
              <div className={'text-left text-sm sm:text-base mt-2 sm:mt-0'}>動画に字幕を設定できます。</div>
            </div>
            <div className={'sm:grid sm:grid-cols-2 mt-4 pt-4'}>
              <div className={'text-left text-lg sm:text-xl font-semibold'}>タスクの配信</div>
              <div className={'text-left text-sm sm:text-base mt-2 sm:mt-0'}>確実に閲覧確認したい場合は、対象者にタスクとして送信できます。</div>
            </div>
            <div className={'sm:grid sm:grid-cols-2 mt-4 pt-4'}>
              <div className={'text-left text-lg sm:text-xl font-semibold'}>コース設定</div>
              <div className={'text-left text-sm sm:text-base mt-2 sm:mt-0'}>マニュアルを学習コースとしてグループ化して配信できます。新入社員のOJTにご利用ください。</div>
            </div>
            <div className={'sm:grid sm:grid-cols-2 mt-4 pt-4'}>
              <div className={'text-left text-lg sm:text-xl font-semibold'}>バーコード検索</div>
              <div className={'text-left text-sm sm:text-base mt-2 sm:mt-0'}>QRコード・バーコードでマニュアルを検索できます。</div>
            </div>
            <div className={'sm:grid sm:grid-cols-2 mt-4 pt-4'}>
              <div className={'text-left text-lg sm:text-xl font-semibold'}>フォルダ・キャビネット</div>
              <div className={'text-left text-sm sm:text-base mt-2 sm:mt-0'}>マニュアルをフォルダで整理できます。フォルダに権限設定することで見せたいマニュアルを絞り込めます。</div>
            </div>
            <div className={'sm:grid sm:grid-cols-2 mt-4 pt-4'}>
              <div className={'text-left text-lg sm:text-xl font-semibold'}>マニュアルの承認</div>
              <div className={'text-left text-sm sm:text-base mt-2 sm:mt-0'}>担当者が作成したマニュアルを上司・管理者が最終チェックできます。</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="mt-16 py-16 rounded-lg text-center">
      <div className="container mx-auto text-center">
        <h3 className="text-3xl lg:text-5xl font-semibold">料金プラン</h3>
        <p className="p-8 text-xl lg:text-2xl mt-6 font-light">
          業界最安クラスのリーズナブルな価格でご提供いたします。
        </p>
        <p className="mt-8">
          <ButtonLink size="xl" className={'rounded-2xl'} path={'/plan'}>料金プランを見る</ButtonLink>
        </p>
      </div>
    </section>
  </Layout>
);

export default Index;
